import './ModalNavigationDrawer.css';
import {
    HomeRegular,
    HomeFilled,
    DocumentAddRegular,
    DocumentAddFilled,
    AppsListRegular,
    AppsListFilled,
    InfoRegular,
    InfoFilled,
    SignOutRegular
} from "@fluentui/react-icons";
import {
    Link,
    useMatch,
    useResolvedPath
} from "react-router-dom";
import { useContext, useEffect } from 'react';
import { UserContext } from '../UserContext';
import { getAuth, signOut } from "firebase/auth";
import avatar from '../assets/default-user-photo.png';



export default function ModalNavigationDrawer() {
    const { userEmail, userDisplayName, userPhotoUrl } = useContext(UserContext);

    useEffect(() => {
        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', window.innerHeight + 'px');
        }
        window.addEventListener('resize', appHeight);
        appHeight();
    }, []);

    const close = e => {
        if (e.target.id === "scrim") {
            document.getElementById('modal-navigation-drawer').classList.remove("modal-navigation-drawer-opened");
            document.getElementById('scrim').classList.remove("scrim-visible");
        }
    }

    const closeOnLink = e => {
        document.getElementById('modal-navigation-drawer').classList.remove("modal-navigation-drawer-opened");
        document.getElementById('scrim').classList.remove("scrim-visible");
    }

    const logOut = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            console.log('Sign-out successful.')
        }).catch((error) => {
            console.log('An error happened.', error)
        });
    }


    return (

        <div className='scrim' id="scrim" onClick={close}>
            <nav className='modal-navigation-drawer' id="modal-navigation-drawer">


                <div className='drawer-account-card'>
                    <img src={userPhotoUrl ? userPhotoUrl : avatar} />
                    <button className='icon-button drawer-account-signout' onClick={logOut}><SignOutRegular fontSize={24} /></button>
                    <div style={{ zIndex: '20' }}>
                        <h4>{userDisplayName ? userDisplayName : 'Welcome'}</h4>
                        <p><small>{userEmail}</small></p>
                    </div>
                </div>
                <hr style={{ margin: '1rem  0', borderColor: 'var(--color-outline)' }}></hr>

                <CustomLink
                    to="/"
                    state={{ title: 'enotar.io' }}
                    icon={<HomeRegular fontSize={24} />}
                    activeicon={<HomeFilled fontSize={24} />}
                    onClick={closeOnLink}>
                    Home
                </CustomLink>

                <CustomLink
                    to="register"
                    state={{ title: 'Register' }}
                    icon={<DocumentAddRegular fontSize={24} />}
                    activeicon={<DocumentAddFilled fontSize={24} />}
                    onClick={closeOnLink}>
                    Register
                </CustomLink>

                <CustomLink
                    to="records"
                    state={{ title: 'Records' }}
                    icon={<AppsListRegular fontSize={24} />}
                    activeicon={<AppsListFilled fontSize={24} />}
                    onClick={closeOnLink}>
                    Records
                </CustomLink>

                <CustomLink
                    to="help"
                    state={{ title: 'Help' }}
                    icon={<InfoRegular fontSize={24} />}
                    activeicon={<InfoFilled fontSize={24} />}
                    onClick={closeOnLink}>
                    Help
                </CustomLink>

                {/* <hr style={{margin: '1rem  0', borderColor: 'var(--outline)'}}></hr> */}

            </nav>
        </div>
    )
};

function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link to={to} {...props} className={match ? "navigation-drawer-item navigation-drawer-item-match" : "navigation-drawer-item"}>
            {match ? props.activeicon : props.icon}
            {children}
        </Link>
    );
}