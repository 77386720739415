import './NavigationBar.css';
import {
    HomeRegular,
    HomeFilled,
    DocumentAddRegular,
    DocumentAddFilled,
    AppsListRegular,
    AppsListFilled,
    InfoRegular,
    InfoFilled
} from "@fluentui/react-icons";
import {
    Link,
    useMatch,
    useResolvedPath 
} from "react-router-dom";

export default function NavigationBar () {
    return(
        <nav className='navigation-bar'>
            <div className='navigation-bar-surface-tint'>

                <CustomLink 
                    to="/" 
                    state={{title: 'enotar.io'}}
                    icon={<HomeRegular fontSize={24} />} 
                    activeicon={<HomeFilled fontSize={24} />}>
                    Home
                </CustomLink>

                <CustomLink
                    to="register" 
                    state={{title: 'Register'}}
                    icon={<DocumentAddRegular fontSize={24} />} 
                    activeicon={<DocumentAddFilled fontSize={24} />}>
                    Register
                </CustomLink>

                <CustomLink
                    to="records" 
                    state={{title: 'Records'}}
                    icon={<AppsListRegular fontSize={24} />} 
                    activeicon={<AppsListFilled fontSize={24} />}>
                    Records
                </CustomLink>

                <CustomLink
                    to="help" 
                    state={{title: 'Help'}}
                    icon={<InfoRegular fontSize={24} />} 
                    activeicon={<InfoFilled fontSize={24} />}>
                    Help
                </CustomLink>

            </div>
        </nav>
    )
};

function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
  
    return (
        <Link to={to} {...props} className="navigation-bar-item">
            <div className={match ? 'navigation-bar-active-indicator navigation-bar-active-indicator-match' : 'navigation-bar-active-indicator'}>
                {match ? props.activeicon : props.icon}
            </div>
          {children}
        </Link>
    );
}