import './Logo.css';

export default function Logo({ className }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640.918 157.282" className={className}>
            <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#0091ea" />
                    <stop offset="1" stopColor="#2962ff" />
                </linearGradient>
                <linearGradient id="linear-gradient-2" y1="-0.016" y2="1.088" href="#linear-gradient" />
                <linearGradient id="linear-gradient-3" x1="0.5" x2="0.5" y2="0.909" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#2962ff" />
                    <stop offset="1" stopColor="#0091ea" />
                </linearGradient>
            </defs>
            <g id="Group_378" data-name="Group 378" transform="translate(-641.425 -469.202)">
                <g id="Group_376" data-name="Group 376" transform="translate(3 7.542)">
                    <path id="Path_309" data-name="Path 309" className="cls-1" d="M34.384-50.176c8.064,0,14.56,5.152,14.784,13.328H19.712C20.944-45.36,26.88-50.176,34.384-50.176ZM63.84-19.488H46.928c-2.016,4.144-5.712,7.5-12.432,7.5-7.84,0-14.112-5.152-14.9-14.336H64.96a36.65,36.65,0,0,0,.448-6.048c0-18.48-12.656-30.688-30.576-30.688C16.464-63.056,3.7-50.624,3.7-31.024,3.7-11.536,16.8,1.008,34.832,1.008,50.176,1.008,60.48-8.064,63.84-19.488ZM115.248,0h15.68V-36.4c0-17.136-10.3-26.544-24.752-26.544-7.952,0-14.672,3.36-18.7,8.624v-7.728H71.792V0h15.68V-34.272c0-9.968,5.488-15.344,14-15.344,8.288,0,13.776,5.376,13.776,15.344ZM200.7-31.024c0-19.6-14-32.032-31.92-32.032s-31.92,12.432-31.92,32.032c0,19.488,13.552,32.032,31.472,32.032C186.368,1.008,200.7-11.536,200.7-31.024Zm-47.936,0c0-12.432,7.5-18.368,15.792-18.368s16.016,5.936,16.016,18.368c0,12.32-7.952,18.368-16.24,18.368C159.936-12.656,152.768-18.7,152.768-31.024Zm56.9,11.76C209.664-5.04,217.616,0,229.488,0h9.856V-13.216h-7.28c-4.928,0-6.608-1.792-6.608-5.936V-49.168h13.888v-12.88H225.456V-77.392H209.664v15.344h-7.392v12.88h7.392Zm31.92-11.984c0,19.376,12.544,32.256,28.224,32.256,9.856,0,16.912-4.7,20.608-10.08V0h15.792V-62.048H290.416V-53.2c-3.7-5.152-10.528-9.856-20.5-9.856C254.128-63.056,241.584-50.624,241.584-31.248Zm48.832.224c0,11.76-7.84,18.256-16.464,18.256-8.4,0-16.352-6.72-16.352-18.48s7.952-18.032,16.352-18.032C282.576-49.28,290.416-42.784,290.416-31.024Zm41.776.112c0-11.984,5.6-15.568,14.9-15.568h4.144V-62.944a21.52,21.52,0,0,0-19.04,10.528v-9.632h-15.68V0h15.68Z" transform="translate(815.737 578.008)" />
                    <path id="Path_308" data-name="Path 308" className="cls-2" d="M25.685-19.624a9.5,9.5,0,0,0-9.866-9.518c-5.92,0-10.1,4.179-10.1,9.518s4.179,9.518,10.1,9.518A9.5,9.5,0,0,0,25.685-19.624Zm5.571,8.705h16.25v-64.3H31.256ZM39.5-82.884c5.688,0,9.982-4.179,9.982-9.518s-4.295-9.518-9.982-9.518c-5.8,0-9.982,4.179-9.982,9.518S33.694-82.884,39.5-82.884Zm78.581,39.813c0-20.313-14.509-33.2-33.081-33.2s-33.081,12.884-33.081,33.2c0,20.2,14.045,33.2,32.616,33.2C103.221-9.874,118.078-22.874,118.078-43.071Zm-49.679,0c0-12.884,7.777-19.036,16.366-19.036s16.6,6.152,16.6,19.036c0,12.768-8.241,19.036-16.831,19.036C75.828-24.035,68.4-30.3,68.4-43.071Z" transform="translate(1161.265 587.819)" />
                </g>
                <g id="Group_261" data-name="Group 261" transform="translate(170.643 0.225)">
                    <g id="Capa_18" data-name="Capa 18" transform="translate(470.782 468.977)">
                        <g id="Group_260" data-name="Group 260" transform="translate(0 0)">
                            <path id="Path_5" data-name="Path 5" className="cls-3" d="M8.014,88.089a46.911,46.911,0,0,1,2.44-14.3C13.8,63.142,17.861,60.031,28.5,43.648c8.914-13.727,8.27-14.942,13.752-21.011C51.72,12.155,59.293,9.41,63.964,8.591A36.534,36.534,0,0,1,70,8c19.892-.263,33.8,8.842,61.273,15.981,5.463,1.42,14.213,3.477,20.321,11.123a27.285,27.285,0,0,1,3.573,5.95C164,60.334,164.855,83.01,164.855,83.01c.555,14.73,1.021,27.112-5.241,41.391-2.657,6.058-6.517,14.143-14.959,20.1-4.467,3.154-8.909,4.508-17.119,7.169-6.719,2.178-9.1,2.364-17.709,4.579-14.983,3.855-13.926,4.879-22.388,6.641-9.442,1.967-18.269,3.806-28.53.779a45.764,45.764,0,0,1-19.015-11.639c-4.384-4.217-4.275-5.6-16.556-25.771-6.585-10.818-7.975-12.653-10.233-17.913C8.189,96.9,8.025,89.873,8.014,88.089Z" transform="translate(-8.013 -7.994)" />
                        </g>
                    </g>
                    <g id="Circulo" transform="translate(495.434 493.699)">
                        <g id="Ellipse_3" data-name="Ellipse 3" className="cls-4" transform="translate(0 0)">
                            <circle className="cls-6" cx="53.919" cy="53.919" r="53.919" />
                            <circle className="cls-7" cx="53.919" cy="53.919" r="50.419" />
                        </g>
                    </g>
                    <g id="Capa_15" data-name="Capa 15" transform="translate(567.021 522.333)">
                        <path id="Path_13" data-name="Path 13" d="M116,68" transform="translate(-116 -68)" />
                    </g>
                    <g id="Group_259" data-name="Group 259" transform="translate(524.121 522.333)">
                        <g id="Capa_14" data-name="Capa 14" transform="translate(0 0)">
                            <path id="Path_11" data-name="Path 11" className="cls-5" d="M117.893,67.985c-2.678-.225-21.62-1.485-36.466,13.294-14.934,14.866-13.665,33.96-13.442,36.614h7.13C74.9,115.69,73.575,99,86.552,86.106c12.726-12.644,28.991-11.49,31.267-11.288Z" transform="translate(-67.863 -67.862)" />
                            <path id="Path_12" data-name="Path 12" d="M121.958,77.417" transform="translate(-73.748 -68.901)" />
                        </g>
                        <path id="Path_14" data-name="Path 14" className="cls-5" d="M76,104.027V96.9a30.912,30.912,0,0,0,19.31-2.005c12.176-5.6,15.973-17.378,16.561-19.31l6.907-.074a38.376,38.376,0,0,1-14.408,21.76C92.012,106.307,78.579,104.437,76,104.027Z" transform="translate(-68.748 -68.817)" />
                        <path id="Path_15" data-name="Path 15" className="cls-5" d="M95.932,88v13.517l13.5-13.5Z" transform="translate(-70.917 -70.176)" />
                    </g>
                </g>
            </g>
        </svg>

    );
}