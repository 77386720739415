import './Home.css';
import img from '../assets/demoimg2.jpg';
import Records from './Records';
import { Link } from 'react-router-dom';

export default function Home() {
    return (
        <div className='page'>
            <h1>For business</h1>

            <section className="home-section-1">

                <div className="elevated-card">
                    <div className="elevated-card-tint">
                        <img src={img} className="elevated-card-img" />
                        <div className="elevated-card-content">
                            <h2>Copyright registration</h2>
                            <p>With enotar.io you can generate an immutable digital proof of authorship that protects your copyright for life and in more than 179 countries.</p>
                            <div style={{ display: 'flex', gap: '.5rem' }}>
                                <Link to="register"><button className="filled-button">Register</button></Link>
                                <Link to="help"><button className="text-button">Learn more</button></Link>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="outlined-card">
                    <div className='outlined-card-content'>
                        <h2>Help</h2>
                        <p>Here you will be able to see articles, videos, and contact technichal support directly.</p>
                        <div style={{ display: 'flex', gap: '.5rem' }}>
                            <Link to="help"><button className="filled-button">Help</button></Link>
                            <a href='https://docs.enotar.io' target='_blank'><button className="text-button">Knowledge center</button></a>
                        </div>
                    </div>
                </div>

            </section>

            <Records />

        </div>
    );
}