import {
    Link,
    useSearchParams,
} from "react-router-dom";
import './Congratulations.css';

export default function Congratulations() {
    let [searchParams, setSearchParams] = useSearchParams();
    let title = searchParams.get("title");
    let fileName = searchParams.get("filename");
    let fileHash = searchParams.get("filehash");
    return (
        <div>
            <h1 style={{ marginBottom: '2rem' }}>Congratulations</h1>

            <div className="congratulations-card">
                <p>The registration of the work: <b>{title}</b>, with the file: <b>{fileName}</b></p>
                <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>and the unique identifier: {fileHash}</p>
                <p>, was successfully completed and is registered in the Emercoin blockchain.</p>
                <p>You can find your records made on the "MY RECORDS" page, also download their respective Copyright Registration Certificate in PDF format.</p>
                <p>As a privacy and security measure, we only store the file ID (encrypted version of the file). We do not store your file, our process happens locally and never uploads to the internet. For that reason, we recommend safely saving the file: <b>{fileName}</b>, on multiple storage hardware and in the cloud of your choice such as: OneDrive, iCloud, Drive, etc...</p>
                <p>Note: This record may take up to 20 minutes to be reflected on the “MY RECORDS” page.</p>
                <Link to="/records">
                    <button className="filled-button">My records</button>
                </Link>
            </div>

        </div>
    );
}