import { BuildingFactoryRegular } from "@fluentui/react-icons";
import React from "react";

export default function Help() {
    return (
        <div className='page'>
            <h1>Help</h1>

            <div className='records-dialog-container'>
                        <BuildingFactoryRegular fontSize={28} />
                        <h3>Help page under development</h3>
                        <p>Here you will be able to see articles, videos, and contact technichal support directly.</p>
                        <div className='records-dialog-buttons-container'>
                            <button className='filled-button'>Go to Home</button>
                        </div>
                    </div>
        </div>
    );
}





{/* <h1>Help 1.0.6</h1>
<h1>{React.version}</h1>
<h1>Headline Large</h1>

<h2>Headline Small</h2>

<h3>Title Large</h3>

<h4>Title Medium</h4>

<p>Paragraph</p>

<p><small>Small Paragraph</small></p>

<p><small><b>Bold Small Paragraph</b></small></p>

<button className='icon-button'>
    <EditRegular fontSize={24} />
</button>

<button className='filled-button'>
    <EditRegular fontSize={24} />
    Filled button
</button>

<button className='filled-button'>
    Filled button
</button>

<button className='filled-tonal-button'>
    Tonal button
</button>

<button className='filled-tonal-button'>
    <EditRegular fontSize={24} />
    Tonal button
</button>

<button className='elevated-button'>
    Elevated button
</button>

<button className='elevated-button'>
    <EditRegular fontSize={24} />
    Elevated button
</button>

<button className='text-button'>
    Text button
</button>

<button className='fab'>
    <EditRegular fontSize={24} />
</button>

<button className='extended-fab'>
    <EditRegular fontSize={24} />
    Register
</button>

<h1>Headline Large</h1>

<h2>Headline Small</h2>

<h3>Title Large</h3>

<h4>Title Medium</h4>

<p>Paragraph</p>

<p><small>Small Paragraph</small></p>

<p><small><b>Bold Small Paragraph</b></small></p> */}