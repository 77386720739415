import { useContext, useEffect, useState } from "react";
import { ArrowDownloadRegular } from "@fluentui/react-icons";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";
import { utf8to16 } from '../utils';
import PoweredByEmercoin from '../assets/powered-by-emercoin.png';
import './Certificate.css';
import { UserContext } from "../UserContext";

export default function Certificate() {
    const { businessLogo } = useContext(UserContext);

    // BLOCK
    const [blockHash, setBlockHash] = useState('');
    const [blockHeight, setBlockHeight] = useState('');
    const [blockDifficulty, setBlockDifficulty] = useState('');
    // TX
    const [transactionId, setTransactionId] = useState('');
    const [confirmations, setConfirmations] = useState('');
    const [timestamp, setTimestamp] = useState('');
    // NVS
    const [NVSname, setNVSname] = useState('');
    const [owner, setOwner] = useState('');
    const [NVSvalue, setNVSvalue] = useState({});
    const [participantsArray, setParticipantsArray] = useState([]);

    let [searchParams, setSearchParams] = useSearchParams();
    const txid = searchParams.get('txid');
    const name = searchParams.get('name');

    var exampleWallet = 'EZ9L4a5ppyaRDZJCisJWf7BNi928SBXiKH';



    useEffect(() => {
        getBlockchainData();

        document.getElementById('cert-download-button').addEventListener('click', e => {
            window.print();
        });

    }, []);

    async function getBlockchainData() {
        try {
            var dataObj = {
                txid: txid,
                name: name
            }
            var url = 'https://node.enotar.io/certificate';
            //var url = 'http://localhost:8080/certificate';
            //var url = 'https://api.enotar.io/certificate';
            const response = await axios.post(url, dataObj);
            //console.log(response);
            var data = response.data;
            // BLOCK
            setBlockHash(data.block.hash);
            setBlockHeight(data.block.height);
            setBlockDifficulty(data.block.difficulty);
            // TX
            setTransactionId(data.transaction.txid);
            setConfirmations(data.block.confirmations);
            const d = new Date(data.transaction.time * 1000);
            setTimestamp(d.toString());
            // NVS
            setOwner(data.nvs.address);
            setNVSname(data.nvs.name);
            setNVSvalue(JSON.parse(utf8to16(data.nvs.value)));
            setParticipantsArray(JSON.parse(utf8to16(data.nvs.value)).participants);
        } catch (error) {
            console.error(error);
        }
    }

    const list = participantsArray.map(data =>
        <tr key={data.id}>
            <td>{data.nationalId}</td>
            <td><code><small>{data.fullname}</small></code></td>
            <td>{data.ISO3166_2}</td>
            <td>{data.role}</td>
        </tr>
    )


    return (
        <div className="page certificate">
            <div className="certificate-header">
                <h1>Proof of authorship certificate</h1>
                <button className="elevated-button" id="cert-download-button" ><ArrowDownloadRegular fontSize={24} />Download</button>
            </div>

            {/* <Logo className="certificate-logo" /> */}
            <img src={businessLogo} style={{marginBottom: '2rem'}} className="certificate-logo" />

            <p>
                This certificate states that on <b>{timestamp}</b> the work entitled <b>"{NVSvalue.title}"</b> obtained a proof of authorship that protects its copyright.
                <br></br><br></br>
                This proof of authorship meets the requirements established by the Bern Convention for the Protection of Literature and Artistic Works; 
                and is formally registered in the Emercoin public blockchain, being accessible to the holder of this certificate at the following address:
                <a href={"https://explorer.emercoin.com/block/" + blockHeight} target="_blank"> https://explorer.emercoin.com/block/{blockHeight}</a>
                <br></br><br></br>
                It has coverage in more than 179 countries and in all future countries that will join the Bern agreement. 
                If the author has this right in one of the signatory countries, he will have it symmetrically in all other countries. 
                It is valid for life, plus 70 years after the author's death, and is publicly accessible.
                <br></br><br></br>
                To identify and trace this proof of authorship, a unique code, called a unique file ID, is generated. 
                The unique file ID uses the Secure hash algorithm (SHA256) to convert the raw binary data of the original digital file into an irreversible 64-character code. 
                This algorithm is Open Source.
                <br></br><br></br>
                Subsequently, the FUID (File Unique ID) together with the personal information of the participants is archived in a decentralized way with a Timestamp in the Emercoin Blockchain in NVS (Name Value Storage) format as follows:
                <br></br><br></br>
                Name: type: enotario name: FUID
                Value: List of participants and their respective roles on JSON format.
                <br></br><br></br>
                In case of litigation or dispute, you must prove that the Unique File ID of your file matches the one registered in the Emercoin Blockchain, so it is recommended to keep it in a safe place.
            </p>
            <br></br>
            <h4>Block details</h4>
            <br></br>
            <div className="certificate-details-container">
                <h5>Block Hash: <p><small>{blockHash}</small></p></h5>
                <h5>Block Height: <p><small>{blockHeight}</small></p> </h5>
                <h5>Difficulty: <p><small>{blockDifficulty}</small></p> </h5>
            </div>
            <br></br>
            <h4>Transaction details</h4>
            <br></br>
            <div className="certificate-details-container">
                <h5>Transaction Id: <p><small>{transactionId}</small></p> </h5>
                <h5>Confirmations: <p><small>{confirmations}</small></p> </h5>
                <h5>Timestamp: <p><small>{timestamp}</small></p> </h5>
            </div>
            <br></br>
            <h4>NVS</h4>
            <br></br>
            <div className="certificate-details-container">
                <h5>Name: <p><small>{NVSname}</small></p></h5>
                <h5>Owner address: <p><small>{owner}</small></p> </h5>
            </div>
            <br></br>
            <h4>Record</h4>
            <br></br>
            <div className="certificate-details-container">
                <h5>Title: <p><small>{NVSvalue.title}</small></p> </h5>
                <h5>File name: <p><small>{NVSvalue.fileName}</small></p> </h5>
                <h5>File's unique ID: <p><small>{NVSvalue.fileHash}</small></p> </h5>
            </div>



            <table id="myTable" style={{margin: '2rem 0'}}>
                <thead>
                    <tr>
                        <th>National Id</th>
                        <th>Fullname</th>
                        <th>ISO3166_2</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {list}
                </tbody>
            </table>


            {/* <img src={PoweredByEmercoin} width='160px' style={{ float: 'right', padding: '2rem 0' }} /> */}

        </div>
    );
}