import React, { createContext, useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink, getIdToken } from "firebase/auth";
import { getFirestore, onSnapshot, doc, getDoc } from "firebase/firestore";
import { walletAddressFromPrivateKey } from './utils';
import axios from 'axios';

export const UserContext = createContext();

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "enotario-91456.firebaseapp.com",
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export function UserProvider({ children }) {
    const [authState, setAuthState] = useState(null);
    const [verified, setVerified] = useState(false);
    const [authenticating, setAuthenticating] = useState(false);
    const [authError, setAuthError] = useState('');
    const [userId, setUserId] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userDisplayName, setUserDisplayName] = useState("");
    const [userPhotoUrl, setUserPhotoUrl] = useState("");
    const [userWallet, setUserWallet] = useState("");
    // DB
    const [credits, setCredits] = useState(0);
    // BUSINESS
    const [businessLogo, setBusinessLogo] = useState("");

    const auth = getAuth();

    useEffect(() => {

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserId(user.uid);
                setUserEmail(user.email);
                setUserDisplayName(user.displayName);
                setUserPhotoUrl(user.photoURL);
                console.log('User Signed in: ', user.uid);

                const idTokenResult = await auth.currentUser.getIdTokenResult();
                setVerified(idTokenResult.claims.verified);

                console.log(idTokenResult.claims.verified)

                const walletAddress = await walletAddressFromPrivateKey(user.uid);

                setUserWallet(walletAddress);

                onSnapshot(doc(db, "users", user.uid), (doc) => {
                    //console.log("Current data: ", doc.data());
                    var data = doc.data();
                    setCredits(data.credits);
                });

                const docRef = doc(db, "businesses", user.email);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    console.log("Document data:", docSnap.data());
                    setBusinessLogo(docSnap.data().logo);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }


                setAuthState(true);

            } else {
                setAuthState(false);

                // setVerified(true);
                // setAuthState(true);
                console.log('User Signed out');
            }
        });

    }, []);

    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }
            setAuthenticating(true);
            signInWithEmailLink(auth, email, window.location.href)
                .then(async (result) => {
                    window.localStorage.removeItem('emailForSignIn');
                    const idToken = await getIdToken(result.user);
                    //var url = 'http://localhost:5001/enotario-91456/us-central1/setCustomClaims';
                    var url = 'https://us-central1-enotario-91456.cloudfunctions.net/setCustomClaims';
                    axios.post(url, {
                        idToken: idToken
                    })
                        .then(async (response) => {
                            //console.log(response);

                            const ux = await auth.currentUser.getIdToken(true);
                            console.log(ux);

                            const idTokenResult = await auth.currentUser.getIdTokenResult();
                            setVerified(idTokenResult.claims.verified);
                            setAuthenticating(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setAuthError('No se pudo verificar este usuario, si crees que es un error por favor contacta al soporte tecnico.');
                            setAuthenticating(false);
                        })

                })
                .catch((error) => {
                    console.log(error);
                    setAuthError('No se pudo verificar este usuario, si crees que es un error por favor contacta al soporte tecnico.');
                    setAuthenticating(false);
                });
        }
    }, []);

    return (
        <UserContext.Provider value={{
            userId,
            authState,
            verified,
            userEmail,
            userDisplayName,
            userPhotoUrl,
            userWallet,
            credits,
            authenticating,
            authError,
            businessLogo
        }}>
            {children}
        </UserContext.Provider>
    );
}