import React, { useContext, useEffect } from 'react';
import './App.css';

// LIBRARIES
import { DocumentAddRegular } from "@fluentui/react-icons";
import { Routes, Route, Navigate } from "react-router-dom";

// NAVIGATION
import TopAppBar from './navigation/TopAppBar';
import NavigationBar from './navigation/NavigationBar';
import NavigationRail from './navigation/NavigationRail';
import NavigationDrawer from './navigation/NavigationDrawer';
import ModalNavigationDrawer from './navigation/ModalNavigationDrawer';

// ROUTES

import Home from './routes/Home';
import Register from './routes/Register';
import Congratulations from './routes/Congratulations';
import Records from './routes/Records';
import Certificate from './routes/Certificate';
import Help from './routes/Help';

import { UserContext } from './UserContext';


export default function App() {
  const { authState, verified } = useContext(UserContext);

  console.log(authState, verified);

  console.log('VERSION 16/SET/22 16:28');

  if (!authState || !verified) { return <Navigate to="/signup" replace={true} />; }
  return (
    <div className="app">

      <TopAppBar />

      <Banner />

      <ModalNavigationDrawer />

      <aside>
        <NavigationRail />
        <NavigationDrawer />
      </aside>

      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="register" element={<Register />} />
          <Route path="congratulations" element={<Congratulations />} />
          <Route path="records" element={<Records />} />
          <Route path="certificate" element={<Certificate />} />
          <Route path="help" element={<Help />} />
        </Routes>
      </main>

      <Routes>
        <Route path="/" element={
          <button className='extended-fab app-fab'>
            <DocumentAddRegular fontSize={24} />
            Register
          </button>
        } />
      </Routes>

      <NavigationBar />

    </div>
  );

}

function Dialog() {
  // useEffect(() => {
  //   setTimeout(() => {
  //     document.getElementById('dialog-scrim').style.display = 'flex';
  //     document.getElementById('dialog-scrim').style.opacity = '1';
  //   }, 2500);
  // }, []);
  return (
    <div className='dialog-scrim' id='dialog-scrim'>
      <div className='dialog' id='dialog'>
        <div className='dialog-tint'>
          <div className='dialog-header'>
            <DocumentAddRegular fontSize={24} />
            <h2>Reset settings?</h2>
          </div>
          <p>For information on button states such as hover, focus, and press, see the button specs.</p>
          <div className='dialog-buttons-container'>
            <button className='text-button' style={{ marginLeft: 'auto' }}>Cancel</button>
            <button className='text-button'>Accept</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Banner() {
  // useEffect(() => {
  //   setTimeout(() => {
  //     document.getElementById('banner').style.display = 'block';
  //   }, 2500);
  // }, []);

  const dimiss = () => {
    document.getElementById('banner').style.display = 'none';
  }

  return (
    <div className='banner' id="banner">
      <div className='banner-content'>
        <p>A new software version was detected, it includes improvements and minor bug fixes.</p>
        <div className='banner-buttons-container'>
          <button className='text-button' style={{ marginLeft: 'auto' }} onClick={dimiss}>Dimiss</button>
          <button className='text-button'>Update now</button>
        </div>
      </div>
    </div>
  );
}