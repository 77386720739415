import './Records.css';
import {
    CertificateRegular,
    AppsListDetailRegular,
    TextBulletListSquareWarningRegular,
    SearchRegular
} from "@fluentui/react-icons";
import PoweredByEmercoin from '../assets/powered-by-emercoin.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState, useContext } from 'react';
import { utf8to16 } from '../utils';
import { UserContext } from '../UserContext';

export default function Records() {
    const { userId, authState, userWallet } = useContext(UserContext);

    const [namesArray, setNamesArray] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    // const walletAddress = "EZ9L4a5ppyaRDZJCisJWf7BNi928SBXiKH";
    const walletAddress = "EZ1L4a5ppyaRDZJCisJWf7BNi928SBXiKH";

    useEffect(() => {
        async function getNames() {
            try {
                console.log(userWallet);
                var dataObj = {
                    wallet: userWallet
                }
                //var url = 'http://localhost:8080/namescan';
                //var url = 'https://api.enotar.io/namescan';
                var url = 'https://node.enotar.io/namescan';
                const response = await axios.post(url, dataObj);
                var nameList = response.data[0];
                // console.log(response);
                var array = [];

                if (nameList.code === -4) {

                } else {
                    nameList.forEach(element => {
                        const d = new Date(element.time * 1000);
                        array.push({
                            name: element.name,
                            value: JSON.parse(utf8to16(element.value)),
                            txid: element.txid,
                            timestamp: d.toLocaleDateString() + " " + d.toLocaleTimeString(),
                            time: element.time
                        });
                    });
                }

                setErrorMessage('');
                array.sort(function(a, b){return b.time - a.time});
                setNamesArray(array);

            } catch (error) {
                console.error(error);
                setErrorMessage(error.message + ' Please contact support.');
            }
        }
        if (userWallet) {
            getNames();
        }
    }, [userWallet]);

    // Handle search
    useEffect(() => {
        var table = document.getElementById("myTable");
        var tr = table.getElementsByTagName("tr");
        var filter = searchValue.toUpperCase();
        for (var i = 0; i < tr.length; i++) {
            var td = tr[i].getElementsByTagName("td")[1];
            if (td) {
                var txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }, [searchValue]);

    const list = namesArray.map(data =>
        <tr key={data.name}>
            <td><Link to={{ pathname: '/certificate', search: '?txid=' + data.txid + '&name=' + data.name }}><button className='icon-button'><CertificateRegular fontSize={24} /></button></Link></td>
            <td>{data.value.title}</td>
            <td><code><small>{data.name}</small></code></td>
            <td>{data.value.fileName}</td>
            <td>{data.timestamp}</td>
        </tr>
    )

    return (
        <div className='page'>

            <div>
                <div className='records-header'>
                    <h1>Records</h1>
                    <div className='records-search-container'>
                        <input type="text" id="myInput" value={searchValue} onChange={e => setSearchValue(e.target.value)}></input>
                        <SearchRegular fontSize={24} />
                    </div>
                </div>


                <div className='table-container'>
                    <div id="middle">
                        <table id="myTable">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Title</th>
                                    <th>File's unique id</th>
                                    <th>File name</th>
                                    <th>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list}
                            </tbody>
                        </table>
                    </div>
                </div>

                {
                    (namesArray.length === 0 && !errorMessage) &&
                    <div className='records-dialog-container'>
                        <AppsListDetailRegular fontSize={28} />
                        <h3>You don't any have records yet</h3>
                        <p>Here you will see the list of all your copyright registries.</p>
                        <div className='records-dialog-buttons-container'>
                            <button className='filled-tonal-button'>View pricing</button>
                            <button className='filled-button'>Register</button>
                        </div>
                    </div>
                }

                {
                    errorMessage &&
                    <div className='records-dialog-container'>
                        <TextBulletListSquareWarningRegular fontSize={28} />
                        <h3>Oops!, something went wrong</h3>
                        <p style={{ color: 'var(--color-error)' }}>{errorMessage}</p>
                    </div>
                }


                <img src={PoweredByEmercoin} width='160px' style={{ float: 'right' }} />
            </div>


        </div>
    );
}