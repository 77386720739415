import Base58 from 'base-58';
import secp256k1 from 'secp256k1';
import CryptoES from 'crypto-es';

// WALLET

export async function walletAddressFromPrivateKey(generator) {
  var privKey = await sha256(generator);
  var pubKey = secp256k1.publicKeyCreate(privKey);
  var pubKeyHash = await sha256(pubKey);
  var ripemdPubKeyHash = CryptoES.RIPEMD160(byteArrayToWordArray(pubKeyHash)).toString(CryptoES.enc.Hex);
  var prefix = new Uint8Array(hexToByteArray('21' + ripemdPubKeyHash));
  var checkSum = await sha256(await sha256(prefix), 'hex');
  var EMCaddress = Base58.encode(hexToByteArray('21' + ripemdPubKeyHash + checkSum.slice(0, 8)));
  return EMCaddress;
}

const hexToByteArray = (hexString) => {
  var result = [];
  for (var i = 0; i < hexString.length; i += 2) {
    result.push(parseInt(hexString.substr(i, 2), 16));
  }
  return result;
}

async function sha256(input, type) {
  if (typeof (input) === 'string') {
    var buf = await crypto.subtle.digest("SHA-256", new TextEncoder("utf-8").encode(input));
  } else {
    var buf = await crypto.subtle.digest("SHA-256", input);
  }

  switch (type) {
    case 'hex':
      return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
      break;

    default:
      return new Uint8Array(buf);
      break;
  }

}

function byteArrayToWordArray(ba) {
  var wa = [],
    i;
  for (i = 0; i < ba.length; i++) {
    wa[(i / 4) | 0] |= ba[i] << (24 - 8 * i);
  }

  return CryptoES.lib.WordArray.create(wa, ba.length);
}


// TEXT

export function utf8to16(str) {
  var out, i, len, c;
  var char2, char3;

  out = "";
  len = str.length;
  i = 0;
  while (i < len) {
    c = str.charCodeAt(i++);
    switch (c >> 4) {
      case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
        // 0xxxxxxx
        out += str.charAt(i - 1);
        break;
      case 12: case 13:
        // 110x xxxx   10xx xxxx
        char2 = str.charCodeAt(i++);
        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = str.charCodeAt(i++);
        char3 = str.charCodeAt(i++);
        out += String.fromCharCode(((c & 0x0F) << 12) |
          ((char2 & 0x3F) << 6) |
          ((char3 & 0x3F) << 0));
        break;
    }
  }

  return out;
}