import './NavigationDrawer.css';
import {
    HomeRegular,
    HomeFilled,
    DocumentAddRegular,
    DocumentAddFilled,
    AppsListRegular,
    AppsListFilled,
    InfoRegular,
    InfoFilled
} from "@fluentui/react-icons";
import {
    Link,
    useMatch,
    useResolvedPath
} from "react-router-dom";

export default function NavigationDrawer() {
    return (
        <nav className='navigation-drawer' id='navigation-drawer'>

            {/* <h5>Navigation</h5> */}

            <Link to='/register'>
                <button className='extended-fab' style={{ width: '100%', marginBottom: '4rem', boxShadow: 'initial' }}>
                    <DocumentAddRegular fontSize={24} />
                    Register
                </button>
            </Link>



            <CustomLink
                to="/"
                state={{ title: 'enotar.io' }}
                icon={<HomeRegular fontSize={24} />}
                activeicon={<HomeFilled fontSize={24} />}>
                Home
            </CustomLink>

            <CustomLink
                to="register"
                state={{ title: 'Register' }}
                icon={<DocumentAddRegular fontSize={24} />}
                activeicon={<DocumentAddFilled fontSize={24} />}>
                Register your works
            </CustomLink>

            <CustomLink
                to="records"
                state={{ title: 'Records' }}
                icon={<AppsListRegular fontSize={24} />}
                activeicon={<AppsListFilled fontSize={24} />}>
                My Records
            </CustomLink>

            <CustomLink
                to="help"
                state={{ title: 'Help' }}
                icon={<InfoRegular fontSize={24} />}
                activeicon={<InfoFilled fontSize={24} />}>
                Help center
            </CustomLink>

            {/* <hr style={{margin: '1rem  0', borderColor: 'var(--outline)'}}></hr> */}


        </nav>
    )
};

function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link to={to} {...props} className={match ? "navigation-drawer-item navigation-drawer-item-match" : "navigation-drawer-item"}>
            {match ? props.activeicon : props.icon}
            {children}
        </Link>
    );
}