import './TopAppBar.css';
import {
    NavigationRegular,
    AddCircleRegular
} from "@fluentui/react-icons";
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import Logo from '../components/Logo';
import CreditIcon from '../assets/credit-icon.svg';

export default function TopAppBar() {
    const { businessLogo, credits } = useContext(UserContext);

    const [title, setTitle] = useState('');

    const location = useLocation();

    useEffect(() => {

        console.log(businessLogo);

        if (location.state) {
            setTitle(location.state.title);
        } else {
            setTitle('Title');
        }

    }, [location]);

    var scrollTrigger = 64;

    window.onscroll = function () {
        if (window.scrollY >= scrollTrigger) {
            document.getElementById("top-app-bar-surface-tint").classList.add("top-app-bar-surface-tint-scroll");
        } else {
            document.getElementById("top-app-bar-surface-tint").classList.remove("top-app-bar-surface-tint-scroll");
        }
    };

    const abrir = () => {
        document.getElementById('modal-navigation-drawer').classList.add("modal-navigation-drawer-opened");
        document.getElementById('scrim').classList.add("scrim-visible");
    }

    return (
        <header className='top-app-bar'>
            <div className='top-app-bar-surface-tint' id='top-app-bar-surface-tint'>
                <button className='icon-button' onClick={abrir}>
                    <NavigationRegular fontSize={24} />
                </button>

                <img src={businessLogo} className="top-app-bar-logo" />

                {/* <Logo className="top-app-bar-logo" /> */}


                {/* <h3 className='top-app-bar-title'>{title}</h3> */}
                <button className='filled-tonal-button' style={{ marginLeft: 'auto' }}>{credits} <img src={CreditIcon} height={24} style={{marginLeft: '0.5rem'}} /></button>
            </div>
        </header>
    )
};