import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext';
import {
    MusicNote2Regular,
    BookOpenRegular,
    DesignIdeasRegular,
    MoviesAndTvRegular,
    CodeRegular,
    RadioButtonRegular,
    RadioButtonFilled,
    InfoRegular,
    DocumentSearchFilled,
    ProductionRegular,
    AddRegular,
    CaretDownRegular,
    DeleteRegular,
    ShieldKeyholeRegular,
    ShareScreenPersonOverlayInsideRegular,
    BrainCircuitRegular,
    CheckmarkFilled,
    DocumentRegular
} from "@fluentui/react-icons";
import {
    useNavigate
} from "react-router-dom";
import './Register.css';
import axios from 'axios';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, updateDoc, increment } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "enotario-91456.firebaseapp.com",
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default function Register() {
    const { credits, userWallet, userId } = useContext(UserContext);

    const [step, setStep] = useState(0);

    const [category, setCategory] = useState('');
    const [title, setTitle] = useState('');
    const [fileHash, setFileHash] = useState('');
    const [fileName, setFileName] = useState('');
    const [participantsArray, setParticipantsArray] = useState([]);

    // SNACKBAR
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarGoToStep, setSnackbarGoToStep] = useState(0);
    // DEBUG
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    let navigate = useNavigate();

    const xTab = document.getElementsByClassName("step");

    useEffect(() => {
        xTab[step].style.display = "block";
        document.getElementById("bottom-progress-bar").style.width = ((step + 1) * 20) + '%';
    }, [step]);

    const prevStep = () => {
        xTab[step].style.display = "none";
        setStep(step - 1);
    };

    const nextStep = () => {
        xTab[step].style.display = "none";
        setStep(step + 1);
    };

    const showSnackbar = () => {
        var element = document.getElementById('snackbar');
        element.style.display = 'flex';
        setTimeout(() => {
            element.style.display = 'none';
        }, 4500);
    }

    const validateFields = () => {
        if (!category) {
            setSnackbarGoToStep(0);
            setSnackbarMessage('You must select a category in 1/5');
            showSnackbar();
            return false;
        } else if (!title) {
            setSnackbarGoToStep(1);
            setSnackbarMessage('You must enter a title in 2/5');
            showSnackbar();
            return false;
        } else if (!fileHash) {
            setSnackbarGoToStep(2);
            setSnackbarMessage('You must select a file in 3/5');
            showSnackbar();
            return false;
        } else if (participantsArray.length === 0) {
            setSnackbarGoToStep(3);
            setSnackbarMessage('You must enter a participant in 4/5');
            showSnackbar();
            return false;
        } else if (category && title && fileHash && participantsArray) {
            return true;
        }
    }

    const submit = () => {
        if (validateFields()) {
            console.log('validado');
            if (credits > 0) {
                console.log('Creditos suficientes');
                document.getElementById('dialog-scrim-confirm').style.opacity = '1';
                document.getElementById('dialog-scrim-confirm').style.display = 'flex';
            } else {
                console.log('Creditos insuficientes');
                document.getElementById('dialog-scrim-credits').style.opacity = '1';
                document.getElementById('dialog-scrim-credits').style.display = 'flex';
            }
        }
    }

    const sendValidatedData = () => {
        setErrorMessage('');
        setLoading(true);
        var obj = {
            title: title,
            category: category,
            fileName: fileName,
            fileHash: fileHash,
            participants: participantsArray
        }
        var url = 'https://node.enotar.io/namenew';
        //var url = "https://api.enotar.io/namenew";
        //var url = 'http://localhost:8080/namenew';

        var data = {
            wallet: userWallet,
            data: obj
        };

        axios.post(url, data)
            .then(async (response) => {
                if (typeof (response.data[0]) === 'string' && response.data[0].length === 64) {
                    console.log(response.data[0]);
                    console.log('Registro exitoso');

                    try {
                        const userRef = doc(db, "users", userId);
                        // Atomically increment the population of the city by 50.
                        await updateDoc(userRef, {
                            credits: increment(-1)
                        });

                        const location = {
                            pathname: '/congratulations',
                            search: '?txid=' + response.data[0] + "&title=" + title + "&filehash=" + fileHash + "&filename=" + fileName
                        }

                        navigate(location);
                    } catch (error) {
                        setLoading(false);
                        console.log(response.data[0]);
                        setErrorMessage(response.data[0]);
                    }

                    // RESTAR CREDITO



                } else {
                    setLoading(false);
                    console.log('Server error');
                    setErrorMessage('HTTPS REQUEST ERROR');
                    if (response.data[0].name === "RpcError") {
                        console.log(response.data[0].message);
                        setErrorMessage(response.data[0].message);
                    }
                }

            })
            .catch((error) => {
                console.log(error);
                setErrorMessage('HTTPS REQUEST ERROR');
                setLoading(false);
            })
    }

    const list = participantsArray.map(data =>
        <tr key={data.id}>
            <td>{data.role}</td>
            <td>{data.fullname}</td>
            <td><code>{data.ISO3166_2}</code></td>
            <td>{data.nationalId}</td>
        </tr>
    )

    return (
        <div className='page register'>

            <DialogCredits />
            <DialogConfirm onConfirm={() => { sendValidatedData(); }} loading={loading} errorMessage={errorMessage} />

            <div>

                <CategoryStep value={category} onChange={(newValue) => { setCategory(newValue) }} />

                <TitleStep value={title} onChange={(newValue) => { setTitle(newValue) }} />

                <FileStep value={fileHash} onChange={(newValue) => { setFileHash(newValue) }} fileNameValue={fileName} onFileNameChange={(newValue) => { setFileName(newValue) }} />

                <RolesStep value={participantsArray} onChange={(newValue) => { setParticipantsArray(newValue) }} />

                <div className="step">
                    <h1>Review & submit</h1>
                    <p style={{ margin: '2rem 0' }}>Before submitting your work, we recommend that you review the information entered and thus ensure that there is no unwanted data or that data is missing. The information cannot be modified once the registration is done.</p>
                    <h3 style={{ marginTop: '.75rem' }}>Category:</h3>
                    {category ? <p>{category.toUpperCase()}</p> : <p style={{ opacity: '0.5' }}><i>You must select a category in 1/5</i></p>}
                    <h3 style={{ marginTop: '.75rem' }}>Title:</h3>
                    {title ? <p>{title}</p> : <p style={{ opacity: '0.5' }}><i>You must enter a title in 2/5</i></p>}
                    <h3 style={{ marginTop: '.75rem' }}>File:</h3>
                    <p style={{ display: 'flex', alignItems: 'center' }}><DocumentRegular fontSize={24} />{fileName}</p>
                    {fileHash ? <code style={{ display: 'flex', alignItems: 'center' }}><ShieldKeyholeRegular fontSize={24} />{fileHash}</code> : <p style={{ opacity: '0.5' }}><i>You must select a file in 3/5</i></p>}

                    <div className='table-container'>
                        <div id="middle">
                            <table id="myTable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Role</th>
                                        <th>Country</th>
                                        <th>ID</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {list}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {participantsArray.length === 0 && <p style={{ opacity: '0.5' }}><i>You must enter a participant in 4/5</i></p>}
                </div>

            </div>

            <div className='snackbar' id='snackbar'>
                <p>{snackbarMessage}</p>
                <button className='text-button' onClick={() => { setStep(snackbarGoToStep); xTab[4].style.display = "none"; }}>View</button>
            </div>

            <div className='bottom-bar'>

                <div className='bottom-bar-tint'>

                    <div className='bottom-progress-bar-container'>
                        <div className='medio'>
                            <div className='bottom-progress-bar' id="bottom-progress-bar" />
                        </div>
                    </div>

                    {
                        step !== 0 && <button className='filled-tonal-button' onClick={prevStep}>Previous</button>
                    }

                    <p className='bottom-progress-bar-step-counter' >{step + 1}/5</p>

                    {
                        step < 4 ? <button className='filled-button' style={{ marginLeft: 'auto' }} onClick={nextStep}>Next</button> : <button className='filled-button' onClick={submit}>Submit</button>
                    }

                </div>
            </div>

        </div>
    );
}

function DialogCredits() {
    const close = () => {
        document.getElementById('dialog-scrim-credits').style.opacity = '0';
        setTimeout(() => {
            document.getElementById('dialog-scrim-credits').style.display = 'none';
        }, 500);

    }

    return (
        <div className='dialog-scrim' id='dialog-scrim-credits' onClick={e => { if (e.target.id === 'dialog-scrim-credits') { close() } }}>
            <div className='dialog'>
                <div className='dialog-tint'>
                    <div className='dialog-header'>
                        <DesignIdeasRegular fontSize={28} />
                        <h2>Insufficient credits</h2>
                    </div>
                    <p>If you require more credit packs, please contact our sales team.</p>
                    <div className='dialog-buttons-container'>
                        <button className='text-button' style={{ marginLeft: 'auto' }} onClick={close}>Accept</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function DialogConfirm({ onConfirm, loading, errorMessage }) {
    const close = () => {
        document.getElementById('dialog-scrim-confirm').style.opacity = '0';
        setTimeout(() => {
            document.getElementById('dialog-scrim-confirm').style.display = 'none';
        }, 500);

    }

    return (
        <div className='dialog-scrim' id='dialog-scrim-confirm' onClick={e => { if (e.target.id === 'dialog-scrim-confirm') { close() } }}>
            <div className='dialog'>
                <div className='dialog-tint'>
                    <div className='dialog-header'>
                        <CheckmarkFilled fontSize={28} />
                        <h2>Confirm</h2>
                    </div>
                    <p>Make sure that all the information entered is correct, since once the registration is done, it cannot be modified.</p>
                    <div className='dialog-buttons-container'>
                        <button className='text-button' style={{ marginLeft: 'auto' }} onClick={close}>Cancel</button>
                        {
                            loading ?
                                <h4>Sending</h4> :
                                <button className='filled-button' onClick={onConfirm}>Accept</button>
                        }
                        <p><small>{errorMessage}</small></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function CategoryStep({ value, onChange }) {
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('From the previous options, select the category that represents your work. By selecting one you will see more details.');

    useEffect(() => {
        switch (category) {
            case 'literary':
                setDescription('With enotar.io you can register any type of literary work such as literature, novels, poetry, lyrics, essays, stories, scripts, translations, anthologies and academic research. Examples of recommended formats are PDF, TXT. It is not recommended to use files that are constantly undergoing changes or updates such as Microsoft Word sessions, Google Docs, Scrivener, Ulysses, etc...');
                break;

            case 'music':
                setDescription('With enotar.io you can register any type of musical or audio work such as scores, audios, compositions, arrangements, demos, rehearsals or participation in projects. Some examples of recommended formats are: MP3, WAV, AIFF, PDF (for sheet music). It is not recommended to use files that are constantly undergoing changes or updates such as ProTools sessions, Cubase, Ableton, Logic Pro, FL Studio, etc...');
                break;

            case 'design':
                setDescription('Protect all your visual works of art such as fashion designs, architectural designs, graphic designs, sculptures, paintings, prints and any other type of original design. Some examples of recommended formats are: JPG, PNG, GIF, TIFF, EPS. It is not recommended to use files that are constantly undergoing changes or updates such as PhotoShop sessions, Illustrator, Corel Draw, InDesign, etc...');
                break;

            case 'audiovisual':
                setDescription('If you work in audiovisual production, cinema, theater or photography, you have the opportunity to protect works such as films, short films, photographs, scripts, storyboards and videos. Some examples of recommended formats are: MP4, MOV, AVI, FLV. It is not recommended to use files that are constantly undergoing changes or updates such as DaVinci Resolve sessions, Adobe Premiere Pro, Final Cut, Avid Media Composer, etc...');
                break;

            case 'code':
                setDescription('If you work in the field of software development and digital products you can also protect your work, to register code it is recommended to take your root directory of the project and convert it into a ZIP file or similar in order to unify the source code in a single file.');
                break;

            case 'other':
                setDescription('If your work does not fit into our preset categories, select this option and continue with the registration. Remember that with enotar.io you can obtain proof of authorship of any type of intellectual or creative work.');
                break;

            default:
                console.log('Default');
                break;
        }
    }, [category]);

    return (
        <div className="step">
            <div className='step-header'>
                <h1>Choose a category{value !== '' && <CheckmarkFilled fontSize={24} className="step-header-check" />}</h1>
                <button className='icon-button'><InfoRegular fontSize={24} /></button>
            </div>

            {/* <p>De las siguientes opciones selecciona la categoría con la que quieras registrar tu obra, de acuerdo a su naturaleza artística</p> */}
            <div className='categories-container'>
                <div onClick={() => { onChange('literary'); setCategory('literary'); }} className={value === 'literary' ? 'category category-active' : 'category'}>
                    {
                        value === 'literary' ?
                            <RadioButtonFilled fontSize={18} className="category-radio-button" color='var(--color-primary)' /> :
                            <RadioButtonRegular fontSize={18} className="category-radio-button" />
                    }
                    <BookOpenRegular fontSize={28} />
                    <p>Literary & research</p>
                </div>
                <div onClick={() => { onChange('music'); setCategory('music'); }} className={value === 'music' ? 'category category-active' : 'category'}>
                    {
                        value === 'music' ?
                            <RadioButtonFilled fontSize={18} className="category-radio-button" color='var(--color-primary)' /> :
                            <RadioButtonRegular fontSize={18} className="category-radio-button" />
                    }
                    <MusicNote2Regular fontSize={28} />
                    <p>Music</p>
                </div>
                <div onClick={() => { onChange('design'); setCategory('design'); }} className={value === 'design' ? 'category category-active' : 'category'}>
                    {
                        value === 'design' ?
                            <RadioButtonFilled fontSize={18} className="category-radio-button" color='var(--color-primary)' /> :
                            <RadioButtonRegular fontSize={18} className="category-radio-button" />
                    }
                    <DesignIdeasRegular fontSize={28} />
                    <p>Design</p>
                </div>
                <div onClick={() => { onChange('audiovisual'); setCategory('audiovisual'); }} className={value === 'audiovisual' ? 'category category-active' : 'category'}>
                    {
                        value === 'audiovisual' ?
                            <RadioButtonFilled fontSize={18} className="category-radio-button" color='var(--color-primary)' /> :
                            <RadioButtonRegular fontSize={18} className="category-radio-button" />
                    }
                    <MoviesAndTvRegular fontSize={28} />
                    <p>Audiovisual & Photo</p>
                </div>
                <div onClick={() => { onChange('code'); setCategory('code'); }} className={value === 'code' ? 'category category-active' : 'category'}>
                    {
                        value === 'code' ?
                            <RadioButtonFilled fontSize={18} className="category-radio-button" color='var(--color-primary)' /> :
                            <RadioButtonRegular fontSize={18} className="category-radio-button" />
                    }
                    <CodeRegular fontSize={28} />
                    <p>Code</p>
                </div>
                <div onClick={() => { onChange('other'); setCategory('other'); }} className={value === 'other' ? 'category category-active' : 'category'}>
                    {
                        value === 'other' ?
                            <RadioButtonFilled fontSize={18} className="category-radio-button" color='var(--color-primary)' /> :
                            <RadioButtonRegular fontSize={18} className="category-radio-button" />
                    }
                    <p>Other</p>
                </div>
            </div>
            <h2>Description</h2>

            <p>{description}</p>

        </div>
    );
}

function TitleStep({ value, onChange }) {

    return (
        <div className="step">
            <div className='step-header'>
                <h1>Enter a title{value !== '' && <CheckmarkFilled fontSize={24} className="step-header-check" />}</h1>

                <button className='icon-button'><InfoRegular fontSize={24} /></button>
            </div>

            <div className="register-title-input-container" style={{ margin: '2rem 0' }}>
                <input className="register-title-input" id="category" type="text" value={value} onChange={(e) => { onChange(e.target.value) }} />
                <label className="register-title-input-label" htmlFor="category">Type the title here</label>
            </div>

            <h2>Description</h2>
            <p>The title of a work, in matters of Copyright, performs the function of identifying it, it can be constituted by a phrase or an expression that indicates the name of the work</p>

        </div>
    );
}

function FileStep({ value, onChange, fileNameValue, onFileNameChange }) {
    // const [fileName, setFileName] = useState('');
    // const [fileHash, setFileHash] = useState('');
    const [loading, setLoading] = useState(false);

    const container = document.getElementById('register-file-container');

    async function sha(str) {
        const buf = await crypto.subtle.digest("SHA-256", new TextEncoder("utf-8").encode(str));
        return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
    }

    const handleClick = () => {
        sha('fabricio').then(res => { alert(res) }).catch(err => { alert(err) });
    }

    async function sha256(message) {
        const data = new Uint8Array(message);
        const hash = await crypto.subtle.digest('SHA-256', data);
        return Array.prototype.map.call(new Uint8Array(hash), x => (('00' + x.toString(16)).slice(-2))).join('');
    }

    const allowDrop = e => {
        e.preventDefault();
        container.style.border = "2px dashed var(--color-primary)";
        container.style.backgroundColor = "var(--color-surface-elevation-3)";
        container.style.boxShadow = "0px 8px 16px rgba(0,0,0,0.1)";
    }

    const leaveDrop = e => {
        e.preventDefault();
        container.style.border = "1px dashed var(--color-outline)";
        container.style.backgroundColor = "var(--color-surface-elevation-1)";
        container.style.boxShadow = "0px 0px 0px rgba(0,0,0,0.2)";
    }

    const dragDrop = e => {
        e.preventDefault();
        container.style.border = "1px dashed var(--color-outline)";
        container.style.backgroundColor = "var(--color-surface-elevation-1)";
        container.style.boxShadow = "0px 0px 0px rgba(0,0,0,0.2)";

        var reader = new FileReader();

        function strToBin(str) {
            var arr = [];

            for (var i = 0; i < str.length; i++) {
                arr.push(str.charCodeAt(i));
            }

            return arr;
        }

        if (e.dataTransfer.items) {
            // Usar la interfaz DataTransferItemList para acceder a el/los archivos)
            for (var i = 0; i < e.dataTransfer.items.length; i++) {
                // Si los elementos arrastrados no son ficheros, rechazarlos
                if (e.dataTransfer.items[i].kind === 'file') {
                    var file = e.dataTransfer.items[i].getAsFile();
                    setLoading(true);
                    onFileNameChange(file.name);
                    //setFileName(file.name);
                    console.log('... file[' + i + '].name = ' + file.name);
                    reader.onload = function (event) {
                        var res = event.target.result;
                        sha256(strToBin(res)).then(digest => {
                            onChange(digest);
                            setLoading(false);
                        }).catch(err => { alert(err) })
                    };

                    reader.readAsBinaryString(file);
                }
            }
        } else {
            // Usar la interfaz DataTransfer para acceder a el/los archivos
            for (var u = 0; u < e.dataTransfer.files.length; u++) {
                console.log('... file[' + u + '].name = ' + e.dataTransfer.files[u].name);
            }
        }
    }

    const handleFiles = event => {
        event.preventDefault();
        var file = event.target.files[0];

        var reader = new FileReader();

        onFileNameChange(file.name);

        function strToBin(str) {
            var arr = [];

            for (var i = 0; i < str.length; i++) {
                arr.push(str.charCodeAt(i));
            }

            return arr;
        }

        setLoading(true);

        reader.onload = function (event) {
            var res = event.target.result;

            sha256(strToBin(res)).then(digest => {
                onChange(digest);
                setLoading(false);
            }).catch(err => { alert(err) });
        };

        reader.readAsBinaryString(file);
    }


    return (
        <div className="step">
            <div className='step-header'>
                <h1>Select a file{value !== '' && <CheckmarkFilled fontSize={24} className="step-header-check" />}</h1>
                <button className='icon-button'><InfoRegular fontSize={24} /></button>
            </div>
            <div className='register-file-container' id="register-file-container" onDragOver={e => allowDrop(e)} onDragLeave={e => leaveDrop(e)} onDrop={e => dragDrop(e)}>
                <DocumentSearchFilled fontSize={28} style={{ color: 'var(--color-primary)' }} />
                <p>Drag & drop your file here to start</p>
                <p>-- or --</p>
                <button className='filled-button' onClick={() => { document.getElementById("fileIn").click() }}>Browse files</button>
                <input type="file" id="fileIn" onChange={handleFiles} style={{ display: 'none' }} />
                {loading &&
                    <div>
                        <ProductionRegular fontSize={28} />
                        <h3>Encrypting...</h3>
                    </div>
                }
            </div>

            <h4>File's unique ID:</h4>
            <p>{fileNameValue}</p>
            <div className='register-file-hash-container'>
                <ShieldKeyholeRegular fontSize={24} />
                <small>
                    <code>{value}</code>
                </small>
            </div>

            <h2>Description</h2>
            <p>You must choose the file of your work which you want to protect. Remember that this file will be encrypted on your device and the encrypted version will be uploaded to the internet (not the original file) this guarantees your privacy regarding the content of your works.</p>
        </div>
    );
}

function RolesStep({ value, onChange }) {
    const [participantsArray, setParticipantsArray] = useState([]);
    const [fullname, setFullname] = useState('');
    const [role, setRole] = useState('');
    const [country, setCountry] = useState('');
    const [nationalId, setNationalId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const participantValidate = () => {
        if (fullname === "" || role === "" || country === "" || nationalId === "") {
            return false;
        } else {
            return true;
        }
    }

    // useEffect(() => {
    //     onChange(participantsArray);
    // }, [participantsArray])

    const addParticipant = (event) => {
        event.preventDefault();

        setErrorMessage('');

        if (participantValidate()) {
            // setParticipantsArray(participantsArray => [
            //     ...participantsArray,
            //     {
            //         role: role,
            //         fullName: fullName,
            //         ISO3166_2: country,
            //         nationalId: nationalId,
            //         id: Date.now()
            //     }
            // ]);

            onChange(value => [
                ...value,
                {
                    role: role,
                    fullname: fullname,
                    ISO3166_2: country,
                    nationalId: nationalId,
                    id: Date.now()
                }
            ])
            setFullname("");
            setRole("");
            setCountry("");
            setNationalId("");
        } else {
            setErrorMessage('Debes ingresa todos los datos de Participante y AGREGAR');
        }
    }

    function removeItem(i) {
        onChange(value.slice(0, i).concat(value.slice(i + 1, value.length)));
    }

    const list = value.map((data) =>
        <div className="register-roles-list-item" key={data.id}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {/* <PersonRegular fontSize={24} /> */}
                <p><b>{data.fullname}</b></p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <ShareScreenPersonOverlayInsideRegular fontSize={24} />
                <p>{data.nationalId}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <BrainCircuitRegular fontSize={24} />
                <p>Role: {data.role}</p>
            </div>
            <p className="register-roles-iso2"><small>ISO3166-2: {data.ISO3166_2}</small></p>
            <button className="icon-button register-roles-list-item-delete" onClick={() => { removeItem(value.indexOf(data)) }}>
                <DeleteRegular fontSize={24} />
            </button>
        </div>
    );

    return (
        <div className="step">
            <div className='step-header'>
                <h1>Add participants and roles{value.length > 0 && <CheckmarkFilled fontSize={24} className="step-header-check" />}</h1>
                <button className='icon-button'><InfoRegular fontSize={24} /></button>
            </div>

            <div className="register-roles-component">

                <form className="register-4-form" onSubmit={addParticipant}>
                    <fieldset>
                        <legend>Add a participant</legend>
                        <div className="input-container">
                            <input className="input-normal" id="fullname" type="text" value={fullname} onChange={(e) => { setFullname(e.target.value) }} />
                            <label className="input-label" htmlFor="fullname">Fullname</label>
                        </div>
                        <div className="input-container">
                            <input className="input-normal" id="role" roletype="text" value={role} onChange={(e) => { setRole(e.target.value) }} />
                            <label className="input-label" htmlFor="role">Role of participation</label>
                        </div>
                        <div className="input-container">
                            <CountrySelect country={country} setCountry={(selection) => { setCountry(selection) }} />
                            <button className="btn-icon input-icon" style={{ pointerEvents: 'none' }} type="button">
                                <CaretDownRegular fontSize={24} />
                            </button>
                        </div>
                        <div className="input-container">
                            <input className="input-normal" id="nationalid" type="text" value={nationalId} onChange={(e) => { setNationalId(e.target.value) }} />
                            <label className="input-label" htmlFor="nationalid">National ID number</label>
                        </div>
                        <button className="filled-button" type="submit"><AddRegular fontSize={24} />Add</button>
                        <p style={{ color: 'var(--color-error)' }}>{errorMessage}</p>
                    </fieldset>

                </form>

                <div className="register-roles-list">
                    <h4>Participants ({value.length})</h4>
                    {list}
                </div>

            </div>

            <h2>Description</h2>
            <p>Protect the rights of creators with the contributions of the various participants in the creative process, it is important that the information and roles of each participant are entered in the registration.</p>

        </div>
    );
}

function ReviewStep({ category, title, fileHash, fileName }) {
    return (
        <div>

        </div>
    );
}

function CountrySelect(props) {
    return (
        <select className="input-normal" name="country" id="country" value={props.country} onChange={(e) => { props.setCountry(e.target.value) }}>
            <optgroup label="Africa">
                <option value="DZ">Argelia</option>
                <option value="BJ">Benín</option>
                <option value="BW">Botsuana</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="CV">Cabo Verde</option>
                <option value="CM">Camerún</option>
                <option value="CF">República Centroafricana</option>
                <option value="TD">Chad</option>
                <option value="KM">Comoras</option>
                <option value="CG">República del Congo</option>
                <option value="CD">República Democrática del Congo</option>
                <option value="CI">Costa de Marfil</option>
                <option value="EG">Egipto</option>
                <option value="GA">Gabón</option>
                <option value="GM">Gambia</option>
                <option value="GH">Ghana</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bisáu</option>
                <option value="GQ">Guinea Ecuatorial</option>
                <option value="KE">Kenia</option>
                <option value="LS">Lesoto</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libia</option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malaui</option>
                <option value="ML">Malí</option>
                <option value="MA">Marruecos</option>
                <option value="MU">Mauricio</option>
                <option value="MR">Mauritania</option>
                <option value="MZ">Mozambique</option>
                <option value="NA">Namibia</option>
                <option value="NE">Níger</option>
                <option value="NG">Nigeria</option>
                <option value="RW">Ruanda</option>
                <option value="ST">Santo Tomé y Príncipe</option>
                <option value="SN">Senegal</option>
                <option value="SZ">Suazilandia</option>
                <option value="ZA">Sudáfrica</option>
                <option value="SD">Sudán</option>
                <option value="TG">Togo</option>
                <option value="TN">Túnez</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabue</option>
            </optgroup>
            <optgroup label="America">
                <option value="AG">Antigua y Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="BS">Bahamas</option>
                <option value="BB">Barbados</option>
                <option value="BZ">Belice</option>
                <option value="BO">Bolivia</option>
                <option value="BR">Brasil</option>
                <option value="CA">Canadá</option>
                <option value="CL">Chile</option>
                <option value="CO">Colombia</option>
                <option value="CR">Costa Rica</option>
                <option value="CU">Cuba</option>
                <option value="DM">Dominica</option>
                <option value="EC">Ecuador</option>
                <option value="SV">El Salvador</option>
                <option value="US">Estados Unidos</option>
                <option value="GD">Granada</option>
                <option value="GT">Guatemala</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haití</option>
                <option value="HN">Honduras</option>
                <option value="JM">Jamaica</option>
                <option value="MX">México</option>
                <option value="NI">Nicaragua</option>
                <option value="PA">Panamá</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Perú</option>
                <option value="DO">República Dominicana</option>
                <option value="KN">San Cristóbal y Nieves</option>
                <option value="VC">San Vicente y las Granadinas</option>
                <option value="LC">Santa Lucía</option>
                <option value="SR">Surinam</option>
                <option value="TT">Trinidad y Tobago</option>
                <option value="UY">Uruguay</option>
                <option value="VE">Venezuela</option>
            </optgroup>
            <optgroup label="Asia">
                <option value="AF">Afganistán</option>
                <option value="SA">Arabia Saudita</option>
                <option value="AM">Armenia</option>
                <option value="BD">Bangladés</option>
                <option value="BH">Baréin</option>
                <option value="BN">Brunéi</option>
                <option value="BT">Bután</option>
                <option value="CN">China</option>
                <option value="AE">Emiratos Árabes Unidos</option>
                <option value="PH">Filipinas</option>
                <option value="GE">Georgia</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IL">Israel</option>
                <option value="JP">Japón</option>
                <option value="JO">Jordania</option>
                <option value="KZ">Kazajistán</option>
                <option value="KG">Kirguistán</option>
                <option value="KW">Kuwait</option>
                <option value="LB">Líbano</option>
                <option value="MY">Malasia</option>
                <option value="MV">Maldivas</option>
                <option value="MN">Mongolia</option>
                <option value="NP">Nepal</option>
                <option value="RU">Rusia</option>
                <option value="OM">Omán</option>
                <option value="PK">Pakistán</option>
                <option value="SG">Singapur</option>
                <option value="LK">Sri Lanka</option>
                <option value="TH">Tailandia</option>
                <option value="TJ">Tayikistán</option>
                <option value="TM">Turkmenistán</option>
                <option value="TR">Turquía</option>
                <option value="UZ">Uzbekistán</option>
                <option value="VN">Vietnam</option>
                <option value="YE">Yemen</option>
            </optgroup>
            <optgroup label="Europa">
                <option value="AL">Albania</option>
                <option value="DE">Alemania</option>
                <option value="AD">Andorra</option>
                <option value="AM">Armenia</option>
                <option value="AT">Austria</option>
                <option value="BE">Bélgica</option>
                <option value="BA">Bosnia y Herzegovina</option>
                <option value="BG">Bulgaria</option>
                <option value="CY">Chipre</option>
                <option value="HR">Croacia</option>
                <option value="DK">Dinamarca</option>
                <option value="SK">Eslovaquia</option>
                <option value="SI">Eslovenia</option>
                <option value="ES">España</option>
                <option value="EE">Estonia</option>
                <option value="FI">Finlandia</option>
                <option value="FR">Francia</option>
                <option value="GE">Georgia</option>
                <option value="GR">Grecia</option>
                <option value="HU">Hungría</option>
                <option value="IE">Irlanda</option>
                <option value="IS">Islandia</option>
                <option value="IT">Italia</option>
                <option value="LV">Letonia</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lituania</option>
                <option value="LU">Luxemburgo</option>
                <option value="MK">Macedonia del Norte</option>
                <option value="MT">Malta</option>
                <option value="MC">Mónaco</option>
                <option value="ME">Montenegro</option>
                <option value="NO">Noruega</option>
                <option value="NL">Países Bajos</option>
                <option value="PL">Polonia</option>
                <option value="PT">Portugal</option>
                <option value="GB">Reino Unido</option>
                <option value="CZ">República Checa</option>
                <option value="RO">Rumania</option>
                <option value="RU">Rusia</option>
                <option value="SM">San Marino</option>
                <option value="RS">Serbia</option>
                <option value="SE">Suecia</option>
                <option value="SH">Suiza</option>
                <option value="UA">Ucrania</option>
            </optgroup>
            <optgroup label="Oceanía">
                <option value="AU">Australia</option>
                <option value="FJ">Fiyi</option>
                <option value="KI">Kiribati</option>
                <option value="SB">Islas Salomón</option>
                <option value="FM">Micronesia</option>
                <option value="NR">Nauru</option>
                <option value="NZ">Nueva Zelanda</option>
                <option value="WS">Samoa</option>
                <option value="TO">Tonga</option>
                <option value="TV">Tuvalu</option>
                <option value="VU">Vanuatu</option>
            </optgroup>
            <option value=""> -- Select a country -- </option>
        </select>
    );
}