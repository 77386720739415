import { useContext, useState } from 'react';
import { Navigate } from "react-router-dom";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { UserContext } from '../UserContext';
import Logo from '../components/Logo';
import {
    MailAlertRegular,
    PeopleErrorRegular
} from "@fluentui/react-icons";
import './SignUp.css';

export default function SignUp() {
    const { authState, verified, authenticating, authError } = useContext(UserContext);

    const [screen, setScreen] = useState('main');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const emailIn = e => {
        e.preventDefault();
        setErrorMessage('');
        const auth = getAuth();
        const actionCodeSettings = {
            //url: 'http://localhost:3000/signup',
            url: 'https://business.enotar.io/signup',
            handleCodeInApp: true
        };
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
                window.localStorage.setItem('emailForSignIn', email);
                console.log('Email sent');
                setScreen('sent');
            })
            .catch((error) => {
                setErrorMessage(error.message);
            });
    }

    if (authState && verified) { return <Navigate to="/" replace={true} />; }


    return (
        <div className="signup">

            <div className='signup-card'>
                {

                    (screen === 'main' && !authenticating && !authError) ?
                        <div className='signup-card-tint'>
                            <Logo className="signup-logo" />
                            <h2>Sign up / Log in</h2>
                            <p>Exclusive access for business customers of enotar.io</p>
                            <form onSubmit={emailIn}>
                                <div className="input-container" >
                                    <input style={{ width: '100%' }} className="input-normal" id="email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                                    <label className="input-label" htmlFor="email">Email</label>
                                </div>
                                <button className='filled-button' type='submit'>Sign up</button>
                                <p style={{ color: 'var(--color-error)' }}>{errorMessage}</p>
                                <p className="signup-terms"><small>
                                    By registering I accept the <a href="https://docs.enotar.io/t%C3%A9rminos-y-condiciones" target="_blank" rel="noreferrer">Terms and Conditions</a> and the
                                    <a href="https://docs.enotar.io/politica-de-privacidad" target="_blank" rel="noreferrer"> Privacy Policies.</a>
                                </small></p>
                            </form>
                        </div> :

                        (screen === 'main' && authenticating) ?
                            <div className='signup-card-tint'>
                                <Logo className="signup-logo" />
                                <h2>Authenticating...</h2>
                            </div> :
                            (screen === 'main' && !authenticating && authError) ?
                                <div className='signup-card-tint'>
                                    <Logo className="signup-logo" />

                                    <PeopleErrorRegular fontSize={28} style={{ alignSelf: 'center' }} />
                                    <h2>This user could not be verified.</h2>
                                    <p style={{color: 'var(--color-error)'}}>{authError}</p>
                                </div> :
                                <div className='signup-card-tint'>
                                    <Logo className="signup-logo" />

                                    <MailAlertRegular fontSize={28} style={{ alignSelf: 'center' }} />
                                    <h2>Check your email</h2>
                                    <p>Check your email inbox: <b>{email}</b> and click on the link "Access to enotar.io" to finish the login process. If you don't see it in your inbox, check your spam bin.</p>
                                </div>
                }

            </div>

        </div>
    );
}